<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="item"  v-for="(item, index) in arr"  :key="index">
        <el-row v-if="index==0">
          <el-col :span="24">
            <span>药品名称</span><span :titie='item.value'>{{ goodsinfo.name }}</span>
          </el-col>
          <el-col :span="24">
            <span>商品编号</span><span :titie='item.value'>{{ goodsinfo.productSn }}</span>
          </el-col>
          <el-col :span="24">
            <span>药品规格</span><span :titie='item.value'>{{ goodsinfo.specification }}</span>
          </el-col>
          <el-col :span="24">
            <span>生产厂家</span><span :titie='item.value'>{{ goodsinfo.manufacturerName }}</span>
          </el-col>
          <el-col :span="24" v-for="(item, index) in entries" :key="index">
            <span>{{ item.name }}</span><span :titie='item.value'>{{ item.value }}</span>
          </el-col>
        </el-row>
        <el-row v-if="index==1">
          <!-- v-for="(item,index) in goodsinfo.albumPics.split(',')" :key="index" -->
          <el-col :span="24">
              <div v-html="goodsinfo.description" ></div>
              <!-- <img  :src="item" alt="index">  -->
          </el-col>
        </el-row>

        <el-row v-if="index==2">
            <p class="decBox"> <b>{{goodsinfo.orderPromotionMsg.length}}</b> 笔 累计已购买 <b>{{goodsinfo.realQuantity}}</b> {{goodsinfo.unit}}</p>
           <el-table
              :data="PurchaseRecord"
              height="250"
              style="width: 100%"
              >
              <el-table-column
                prop="memberName"
                label="购买用户名字"
                width="180">
              </el-table-column>
              <el-table-column
              align="center"
                prop="goodCount"
                label="活动购买数量"
                width="180">
              </el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="订单创建日期">
              </el-table-column>
            </el-table>
        </el-row>
         <el-row v-if="index==3" class="ruleBox">
           <div>
                <p> <b>1、</b> 药店用户参团需先按成团价支付货款，支付成功后即参团成功；</p>
                <p> <b>2、</b>拼团有效时间内，该拼团活动已付款参团商品数量达到成团数量时， 订单即刻生效并及时发货；若有效时间内未成团，则将会将已支付货款退回 到您的预存款账户余额；</p>
                <p> <b>3、</b>可将拼团信息通过微信分享给药店的朋友，共同参团以期更快成团；</p>
                <p> <b>4、</b>本活动仅限于大药房订货系统注册药店用户参与，普通消费者不可参与此拼团活动。</p>
           </div>
            <!-- ruleBg.png -->
            <div>
              <img src="@/assets/ruleBg.png" alt="">
            </div>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "DetailInfo",
  data() {
    return {
      arr: ["商品参数", "商品详情图","采购记录","拼团规则"],
      activeName: 0,
      entries:'',
   PurchaseRecord:[]
    };
  },
   props: {
    goodsinfo:{
         required: true,
      // type: Object,
    },
     arrGoods: {
      type: Object,
    },
   },
  components: {},
  methods: {
    handleClick() {
      // tab, event
    },
   
  },
  watch: {
   
  },
created(){
  console.log('.parameterValues.parameterValues',this.goodsinfo)
  if(this.goodsinfo.parameterValues!="" && this.goodsinfo.parameterValues!=undefined){
    
    if(this.goodsinfo.length!=0){

      // console.log(JSON.parse(this.goodsinfo.parameterValues))
      this.entries=JSON.parse(this.goodsinfo.parameterValues)[0].entries;
    }
  }

this.goodsinfo.orderPromotionMsg.forEach(item => {
   let disLength = item.memberName.length;
  item.memberName=item.memberName.substring(0,2)+'**'+item.memberName.substring(disLength-2,disLength)
});
  this.PurchaseRecord=this.goodsinfo.orderPromotionMsg
  //  let i=0;
  //   for (i = 0; i <100; i++) {
  //      console.log(i)
  //       this.PurchaseRecord.push(this.goodsinfo.orderPromotionMsg[0])
  //    }
           
  },
  mounted() {
  },
};
</script>
<style lang="less">
.detailInfo {
  .el-table__body td{
    padding:4px 0;
    color: #909399;
  }
  .ruleBox{
    div:nth-child(1){
      float: left;
      width:calc( 60% - 90px );
      padding:30px; 
      margin-right:20px;
      border: 2px solid #fec49f;
        p{
          line-height:36px;
         color: #666;
         font-family: fangsong;
         b{
           font-weight: bold;
           color: #666;
         }
      }
    }
    div:nth-child(2){
      float: right;
      width:calc( 40% - 50px );
      padding:15px 25px 20px;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  .el-tabs__header {
    font-weight: bold;
    height: 50px;
    background: #f6f6f6;
    margin: 0;
    border: 0;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        color: #666;
        font-size: 16px;
        display: inline-block;
        width: 120px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        border: none;
      }
      .el-tabs__item.is-active {
        background: #FFD303;
        color: #000;
      }
    }
  }
  .el-tab-pane {
    padding: 13px 50px;
    .el-col.el-col-24 {
      line-height:32px;
      font-size: 15px;
      img{
        width: 100%;
      }
    
      span:nth-child(1) {
        color: #666;
        width: 110px;
        margin-right:20px;
        display:block;
        float: left;
      }
      span:nth-child(2) {
        color: #333;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        display:block;
        float: left;
         margin-right:20px;
        width: calc(100% - 150px);
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import "~style/index.less";
.detailInfo {
  padding-bottom: 16px;
  .decBox{
    text-indent:10px;
    color: #9093b3;
    b{
      color: red;
    }
  }
}
</style>
